.footer{
    background-color: var(--container-color);
}
.footer-grid{
    grid-template-columns: 5fr  3fr 3fr;
    padding-block: 6.25rem 4.5rem;
}

.footer-title,
.footer-logo-img{
    margin-bottom: 2rem;
}
.footer-logo-img{
    width: 190px;
}
.footer-description{
    margin-bottom: 1.25rem;
}
.footer-conntact-item{
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 1rem;
}
.footer-conntact-item .icon{
    color: var(--first-color);
    font-size: var(--larger-font-size);
}
.footer-title{
    font-size: var(--h3-font-size);
    letter-spacing: 1.5px;
    position: relative;
    padding-bottom: 1.25rem;

}
.footer-title::after,
.footer-title::before{
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: var(--first-color);
}
.footer-title::before{
    left: 0;
    width: 25px;
}
.footer-links,
.footer-link{
    display: flex;
}
.footer-links{
    flex-direction: column;
    gap: 1.25rem;
}
.footer-link{
    color: var(--title-color);
    align-items: center;
    column-gap: 0.25rem;
}
.footer-link .icon{
    color: var(--first-color);
}
.subscribe-input{
    border: 2px solid var(--title-color);
    width: 90%;
}
.subscribe-input::placeholder{
    /* color: var(--title-color); */
    color: #000;
}
.subscribe-button{
    width: 30%;
    justify-content: center;
    margin-top: 1.25rem;
    transition: .3s ease-in-out;
    /* color: #000; */
}
.footer-socials-follow{
    font-size: var(--normal-font-size);
    margin-block: 1.5rem 1rem;
}
.footer-socials-link{
    display: flex;
    column-gap: 1rem;
}
.footer-social-link{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: var(--title-color);
    border: 2px solid var(--first-color);
    display: grid;
    place-items: center;
    transition: 0.5s var(--transition);
}
.footer-social-link:hover{
    background-color: rgb(206, 148, 25);
    /* rotate: 360deg; */
}
.copyright-text{
    padding: block 1.25rem;
    border-top: 2px solid hsla(0, 0%, 100%, 0.1);
    text-align: center;
    color: var(--title-color);
}
.copyright-text span{
    color: rgba(251, 70, 9, 0.886);
   
}
/* Media queries for responsiveness */

/* Tablets and larger smartphones */
@media (max-width: 992px) {
    .footer-grid {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .footer-title {
        font-size: var(--h4-font-size);
    }

    .footer-logo-img {
        width: 160px;
    }

    .footer-conntact-item .icon {
        font-size: var(--large-font-size);
    }

    .footer-social-link {
        width: 34px;
        height: 34px;
    }

    .subscribe-button {
        width: 40%;
    }
}

/* Smartphones */
@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: 1fr;
    }

    .footer {
        padding-block: 4rem 2rem;
    }

    .footer-logo-img {
        width: 140px;
    }

    .footer-title {
        font-size: var(--h5-font-size);
    }

    .footer-conntact-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .footer-social-link {
        width: 30px;
        height: 30px;
    }

    .subscribe-button {
        width: 50%;
    }
}

/* Extra small devices (phones, small tablets) */
@media (max-width: 480px) {
    .footer {
        padding-block: 2rem 1rem;
    }

    .footer-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .footer-logo-img {
        width: 120px;
    }

    .footer-title {
        font-size: var(--h6-font-size);
    }

    .footer-conntact-item .icon {
        font-size: var(--normal-font-size);
    }

    .footer-social-link {
        width: 26px;
        height: 26px;
    }

    .subscribe-input {
        width: 100%;
    }

    .subscribe-button {
        width: 60%;
    }
}