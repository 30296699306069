.contact{
   
    padding: 5rem;
    position: relative;
}
.contact-from{
    grid-template-columns: repeat(4, 1fr);
}
.from-input-textarea,
.contact-btn
{
    grid-column: 1/3;
}
.from-input{
    color: black;
 
    border: 1px soild black;
    padding: 1rem 1.20rem;
}
.contact,
.from-input{
    border-radius: var(--radius);
}

.from-input ::placeholder{
    /* color: blanchedalmond; */
}
.from-input-textarea .from-input {
    height: 120px;
    resize: none;

}
.contact-btn{
    justify-content: center;
}
/* Media queries for responsiveness */

/* Tablets and larger smartphones */
@media (max-width: 992px) {
    .contact {
        padding: 3rem;
    }

    .contact-from {
        grid-template-columns: repeat(2, 1fr);
    }

    .from-input-textarea,
    .contact-btn {
        grid-column: 1 / -1;
    }
}

/* Smartphones */
@media (max-width: 768px) {
    .contact {
        padding: 2rem;
    }

    .contact-from {
        grid-template-columns: 1fr;
    }

    .from-input-textarea,
    .contact-btn {
        grid-column: 1 / -1;
    }
}

/* Extra small devices (phones, small tablets) */
@media (max-width: 480px) {
    .contact {
        padding: 1rem;
    }

    .from-input {
        padding: 0.75rem 1rem;
    }

    .from-input-textarea .from-input {
        height: 100px;
    }

    .contact-btn {
        justify-content: center;
    }
}