/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
* {
  /* padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Outfit; */
  scroll-behavior: smooth;
}

/* body{
  min-height: 100vh;
}
a{
  text-decoration: none;
  color: inherit;
} */
.app {
  width: 100%;
  margin: auto;
}

/* @keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
} */

 @media screen and (max-width: 1200px) {
  .container {
    max-width: 960px;
  }

  .features-grid,
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .features-grid {
    row-gap: 3rem;
  }
}

@media screen and (max-width: 992px) {
  :root {
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.375rem;
    --h3-font-size: 1.25rem;
    --largest-font-size: 1.25rem;
    --larger-font-size: 1.125rem;
    --large-font-size: 1rem;
    --normal-font-size: 0.9375rem;
  }
   .container {
    max-width: 720px;
  }

  .btn {
    padding: 0.75rem 1.25rem;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__logo-img,
  .footer-logo-img {
    width: 140px;
  }

  /* .nav-menu {
    position: absolute;
    top: 100%;
    inset-inline: 0.75rem;
    background-color: blue;
    max-width: 0px;
    overflow: scroll;

  } */

  .nav-list {
    flex-direction: column;
  }

  .nav_item {
    padding: 0.5rem 1.25rem;
  }

  .nav-link {
    color: black;
  }

  .nav-toggle {
    display: block;
  }

  .about-grid {
    grid-template-columns: 1fr;
  }
  .contact-from{
    row-gap: 5rem;
  }
}

@media screen and (max-width: 768px) {
  .container{
    max-width: 540px;
  }
  .features-item{
    grid-template-columns: 1fr;
  }
  .features-img-wrapper{
    margin-right: 0;
  }
  .features-images{
    vertical-align: middle;
  }
  .footer-grid{
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 576px) {}  