/* src/pages/RefundPolicy.css */

.refund-policy-container {
    /* padding: 20px; */
    /* max-width: 3rem; */
 /* width: 100%;
 height: 100vh; */
   
}
.xyz{
    background-image:  url(../../Assets/term.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    height: 60vh ;
    display: flex;
    justify-content:center;
    align-items: center;
    background-size: cover;

}
.xyz h1{
    
    color: white;
    font-size: 60px;
    font-style:  italic ;

}
.refundcontent h1 {
    font-size: 2em;
    margin-bottom: 20px;
    align-items: center;
}

.contents h2 {
    font-size: 1.5em;
    margin-top: 20px;
}

.contents p {
    font-size: 1em;
    margin-bottom: 10px;
}

.contents strong {
    font-weight: bold;
}
