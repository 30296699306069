.app-download{
    margin: auto auto;
    margin-top: 100px;
    font-size: max(3vw,20px);
    text-align: center;
    font-weight: 500;
}
.app-download-platforms{
    display: flex;
    justify-content: center;
    gap: max(2vw,10px);
    margin-top: 40px;
}
.app-download-platforms img{
    width: max(30vw,120px);
    max-width: 180px;
    transition: 0.5s;
    cursor: pointer;

}
.app-download-platforms img:hover{
    transform: scale(1.05);
}
/* Media queries for responsiveness */

/* Tablets and larger smartphones */
@media (max-width: 992px) {
    .app-download {
        font-size: max(4vw, 18px);
        margin-top: 80px;
    }

    .app-download-platforms {
        gap: max(3vw, 15px);
    }

    .app-download-platforms img {
        width: max(35vw, 100px);
        max-width: 160px;
    }
}

/* Smartphones */
@media (max-width: 768px) {
    .app-download {
        font-size: max(5vw, 16px);
        margin-top: 60px;
    }

    .app-download-platforms {
        gap: max(4vw, 20px);
    }

    .app-download-platforms img {
        width: max(40vw, 80px);
        max-width: 140px;
    }
}

/* Extra small devices (phones, small tablets) */
@media (max-width: 480px) {
    .app-download {
        font-size: max(6vw, 14px);
        margin-top: 40px;
    }

    .app-download-platforms {
        flex-direction: column;
        gap: 20px;
    }

    .app-download-platforms img {
        width: max(50vw, 70px);
        max-width: 120px;
    }
}