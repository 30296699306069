/* ===================
GOOGLE FONTS
===================*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/*============
CSS VARIABLES
============*/

:root {
  --header-height: 5rem;


  /*===================
* COLORS
===================*/

  --first-color: #f6f3fa;
  --first-color-light: #344C64;
  --text-color: #151414;
  --title-color: hsl(0, 0%, 100%);
  --bg-color: white;
  --container-color: #577B8D;
  --hsla: hsla(0, 0%, 0);

  /*=============
* Typography
=============*/
  --body-font: "Roboto", sans-serif;
  --second-font: "Roboto", sans-serif;

  --biggest-font-size:  clamp(2rem, 1.125rem + 4vw, 4.125rem);;
  --bigger-font-size:  clamp(1.875rem, 1.3603rem + 2.3529vw, 3.125rem);
  --big-font-size: clamp(1.75rem, 1.4412rem + 1.4118vw, 2.5rem);
  --h1-font-size: 1.875rem;
  --h2-font-size: 1.75rem;
  --h3-font-size: 1.5rem;
  --largest-font-size: 1.375rem;
  --larger-font-size: 1.25rem;
  --large-font-size: 1.125rem;
  --normal-font-size: 1rem;


  --weight-400: 400;
  --weight-500: 500;
  --weight-600: 600;
  --weight-700: 700;

  /*======
*Border-radius
=======*/

  --radius: 0.5rem;

  /*==========
* Transition
======*/
  --transition:  ease-in-out;


}

/*===========
Reset
==========*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
textarea,
button,
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  color: var(--text-color);
  background-color: var(--bg-color);
}

h1,
h2,
h3 {
  font-family: var(--second-font);
  color: var(--title-color);
  font-weight: var(--weight-400);
}

ul {
  list-style: none;
}

p {
  line-height: 1.8;
}

a {
  text-decoration: none;
}

input,
textarea {
  border: 2px solid burlywood;
  outline: none;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 5px;
  resize: none;
}

img {
  max-width: 100%;
}
.container {
  max-width: 90%;
  margin-inline: auto;
  padding-inline: 0.75rem;
}

.btn {
  display: inline-block;
  /* color: var(--title-color); */
  /* background: var(--first-color); */
  border: 2px solid var(--first-color);
  padding: 1rem 1.5rem;
  border-radius: var(--radius);
  color: white;
  background: #151414;

}

.btn:hover {
  /* background-color: transparent; */
  color: white;
  background-color: #b9d5f1;
  cursor: pointer;
}
.btn-flex{
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.section {
  padding-block: 7.5rem;
  gap: 2rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section-title {
  font-size: var(--big-font-size);
  text-align: center;
  /* margin-bottom: 1.5rem; */
}

.section-title::before {
  content: attr(data-title);
  display: block;
  color: var(--first-color);
  font-family: var(--second-font);
  font-size: var(--h2-font-size);
  letter-spacing: 2px;
  /* margin-bottom: 0.5rem; */
}
.title-left{
  text-align: left;
  margin-bottom: 1rem;
}