
  .about {
    background:url(../../Assets/abc.png) center / cover fixed;
    position: relative;
    width: 100vw;
    height: 100vh;
    
  }
  
 
  
  .section-title {
    color: rgb(255, 255, 255);
    padding-block: 2.5rem;
  }
  .section-title h2{
    padding-left: 35rem;
  }
  
  .about-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    gap: 0rem;
  }
  
  .about-img-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .about_img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    border: 2px solid #000; 
  }
  
  .about-content-wrapper {
    flex: 1;
    padding: 2rem; 
    border-radius: 20px; 
    background-color: #f9f9f9; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    height: 250px; /* Set the height of the container */
    width: 50%; /* Set the width of the container */
    margin-right: 100%; /* Ensure the container is on the right side */
  }
  
  .about-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
  }
  
  /* .about-content {
    text-align: justify;
  } */
  
  .about-title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: .8rem;
    text-align: center;
    margin-top: .6rem;

  }
  
  .about-description {
    font-size: 1rem;
    line-height: 1.2rem;
    max-width: 80%;
    text-align: center;
    margin: auto;
    padding-top: 1rem;
    color: #000;
  }
  
  .swiper-pagination {
    position: initial;
    margin-right: 2rem;
  }
  
  .swiper-pagination-bullet {
    width: 16px;
  }
  /* Media queries for responsiveness */

/* Tablets and larger smartphones */
@media (max-width: 992px) {
  .section-title h2 {
      padding-left: 20rem;
  }

  .about-container {
      flex-direction: column;
      align-items: center;
  }

  .about-content-wrapper {
      width: 80%;
      margin-right: 0;
      margin-top: 2rem;
  }

  .about-title {
      font-size: 1.75rem;
  }

  .about-description {
      font-size: 0.9rem;
  }
}

/* Smartphones */
@media (max-width: 768px) {
  .section-title h2 {
      padding-left: 10rem;
  }

  .about-img-wrapper, .about-content-wrapper {
      width: 100%;
  }

  .about-content-wrapper {
      height: auto;
      padding: 1rem;
  }

  .about-title {
      font-size: 1.5rem;
  }

  .about-description {
      font-size: 0.8rem;
      line-height: 1.1rem;
  }
}

/* Extra small devices (phones, small tablets) */
@media (max-width: 480px) {
  .section-title {
      text-align: center;
      padding-block: 1.5rem;
  }

  .section-title h2 {
      padding-left: 0;
      font-size: 1.5rem;
  }

  .about-content-wrapper {
      width: 90%;
      padding: 1rem;
      margin-top: 1rem;
  }

  .about-title {
      font-size: 1.25rem;
  }

  .about-description {
      font-size: 0.75rem;
      line-height: 1rem;
  }
}
/* Larger screens */
@media (min-width: 1200px) {
  .section-title h2 {
      padding-left: 40rem;
  }

  .about-content-wrapper {
      width: 60%;
      height: 300px;
      padding: 3rem;
  }

  .about-title {
      font-size: 2.5rem;
  }

  .about-description {
      font-size: 1.25rem;
  }
}

@media (min-width: 1600px) {
  .section-title h2 {
      padding-left: 50rem;
  }

  .about-content-wrapper {
      width: 60%;
      height: 350px;
      padding: 4rem;
  }

  .about-title {
      font-size: 3rem;
  }

  .about-description {
      font-size: 1.5rem;
  }
}
@media (min-width: 1600px) and (max-width: 1920px) {
  .section-title h2 {
      padding-left: 45rem; /* Adjust as needed */
      padding-top: 9rem;
  }
  .about-content-wrapper {
      width: 70%; /* Adjust as needed */
      height: 320px; /* Adjust as needed */
      padding: 3.5rem;
  }
  .about-title {
      font-size: 2.75rem; /* Adjust as needed */
  }
  .about-description {
      font-size: 1.4rem; /* Adjust as needed */
  }
}