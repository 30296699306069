/* Base Styles */
.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content */
    width: 100%;
    overflow: hidden;
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../Assets/home521.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(1px); /* Blur only the image */
    z-index: -1; /* Place it behind the content */
}


body {
    overflow-x: hidden;
}

/* Keyframes for typing  */
/* Define the default keyframes */
@keyframes typing {
    from {
        width: 100;
    }
    to {
        width: 25%;
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: orange; 
    }
}

/* Add a media query for small screens */
@media (max-width: 600px) {
    @keyframes typing {
        from {
            width: 100;
        }
        to {
            width: 80%;
        }
    }

    @keyframes blink-caret {
        from, to {
            border-color: transparent;
        }
        50% {
            border-color: red; /* Change color for smaller screens if needed */
        }
    }
}

/* Add a media query for medium screens */
@media (min-width: 601px) and (max-width: 1024px) {
    @keyframes typing {
        from {
            width: 100;
        }
        to {
            width: 80%;
        }
    }

    @keyframes blink-caret {
        from, to {
            border-color: transparent;
        }
        50% {
            border-color: blue; /* Change color for medium screens if needed */
        }
    }
}

/* Add a media query for large screens */
@media (min-width: 1025px) {
    @keyframes typing {
        from {
            width: 100;
        }
        to {
            width: 30%;
        }
    }

    @keyframes blink-caret {
        from, to {
            border-color: transparent;
        }
        50% {
            border-color: orange; /* Change color for large screens if needed */
        }
    }
}

/* Usage of the animations in elements */
.typing-element {
    animation: typing 2s infinite;
}

.blinking-caret {
    animation: blink-caret 1s step-end infinite;
}



.home-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-block: 0.5rem;
}

.home-content {
    margin-top: 2.5rem;
}

.home-subtitle,
.home-description {
    color: var(--title-color);
}

.home-subtitle {
    font-family: var(--second-font);
    font-size: var(--h1-font-size);
    font-weight: var(--weight-500);
    overflow: hidden; 
    white-space: nowrap; 
    border-right: 0.15em solid orange;
    width: 0; 
    animation: typing 3.5s steps(40, end) forwards, blink-caret 0.75s step-end infinite;
}

.home-title {
    font-size: var(--biggest-font-size);
    font-weight: var(--weight-700);
    margin-block: 1.25rem;
}

.home-content h2 {
    margin-block: 2rem;
    font-size: 2rem;
    font-weight: 600;
}

.home-description {
    margin-bottom: 2.20rem;
}

.home-btns {
    display: flex;
    column-gap: 2rem;
}

.home-btn {
    background-color: transparent;
    transition: 0.3s ease-in-out;
    border: 1px solid #ffffff;
    font-size: 1.2rem;
    font-weight: 500;
}

.home-btn:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Media Queries */

/* Tablets and larger smartphones */
@media (max-width: 992px) {
    .home-content {
        margin-top: 2rem;
    }

    .home-subtitle {
        font-size: 1.5rem;
    }

    .home-title {
        font-size: 2rem;
    }

    .home-content h2 {
        font-size: 1.75rem;
    }

    .home-description {
        font-size: 1rem;
    }

    .home-btn {
        font-size: 1rem;
    }
}

/* Smartphones */
@media (max-width: 768px) {
    .home-container {
        flex-direction: column;
        text-align: center;
        padding: 1rem;
    }

    .home-content {
        margin-top: 1.5rem;
    }

    .home-subtitle {
        font-size: 1.25rem;
    }

    .home-title {
        font-size: 1.75rem;
    }

    .home-content h2 {
        font-size: 1.5rem;
    }

    .home-description {
        font-size: 0.9rem;
    }

    .home-btns {
        flex-direction: column;
        row-gap: 1rem;
    }

    .home-btn {
        font-size: 0.9rem;
        width: 100%;
    }
}

/* Extra small devices (phones, small tablets) */
@media (max-width: 480px) {
    .home-subtitle {
        font-size: 1rem;
    }

    .home-title {
        font-size: 1.5rem;
    }

    .home-content h2 {
        font-size: 1.25rem;
    }

    .home-description {
        font-size: 0.8rem;
    }

    .home-btn {
        font-size: 0.8rem;
    }
}
