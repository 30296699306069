.header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
}

.nav {

    height: calc(var(--header-height)  );
    /* background-color: var(--container-color); */
    display: flex;
    align-items: center;
    /* position: absolute; */
    /* z-index: 100; */
    justify-content: space-between;
     position: relative; 
}

.nav__logo-img {
    width: 190px;
    cursor: pointer;
}

.nav-list {
    display: flex;
    column-gap: 2rem;
}

.nav-link,
.nav-toggle {

    color: var(--title-color);
    cursor: pointer;
}

.nav-link {
    font-size: var(--large-font-size);
    font-weight: var(--weight-500);
}

.nav-toggle {
    font-size: var(--h2-font-size);
    display: none;
}

/*=============
* Scroll Header
===========*/
.scroll-header {
    position: fixed;
    background-color: black;
    animation: slide-down 0.7s
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.active {
    color: var(--first-color);
    padding-bottom: 2px;
    border-bottom: 2px solid #49557e;
}


@media screen and (max-width: 768px) {
    .nav-menu {
      display: none;
    }
  
    .nav-toggle {
      display: block;
    }
  
    .show-menu {
      display: block;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #6f0d0d;
      width: 100%;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
  
    .show-menu .nav-list {
      flex-direction: column;
      align-items: center;
    }
  
    .nav_item {
      margin: 1rem 0;
    }
  }