.features{ 
    background: url(../../Assets/def.png) center / cover fixed ; 
    position: relative;
    width: 100%;
   
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
}
.section-title {
    position: relative;
    z-index: 1;
    padding-block: 2.5rem;
   
} 

.section-title h1{
    padding-left: 12rem;
}

.line{
    background: rgb(197, 195, 251);
    height: .2vh;
    width: 10%;
    filter: blur(1.5px);
    margin-bottom: 2rem;
}
.fetch{
    padding-right: 5rem ;
}



.features-holder{
    width: 90%;
   
    margin: auto;
}
.abc{
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    padding-block: 1.5rem;
   
}
 
.features-content{
    background: #fff;
    max-width: 60%;
    text-align: left;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
    border-radius: 8px;

    
}
.features-content h3{
    color: #000;
    padding-top: 2rem;
    font-size: 1.4rem;
    font-weight: 450;
}

.features-img-wrapper img{
    max-width: 65%;
    /* max-height: 50%; */
}
.features-title{
    color: #000;
    font-weight: 550;
}
/* Media queries for responsiveness */

/* Tablets and larger smartphones */
@media (max-width: 992px) {
    .section-title h1 {
        padding-left: 6rem;
        font-size: 1.8rem;
    }

    .features-content {
        max-width: 70%;
        padding: 2rem;
    }

    .features-content h3 {
        font-size: 1.2rem;
    }

    .features-img-wrapper img {
        max-width: 75%;
    }
}

/* Smartphones */
@media (max-width: 768px) {
    .section-title h1 {
        padding-left: 3rem;
        font-size: 1.6rem;
    }

    .features {
        height: auto;
        padding: 2rem 0;
    }

    .abc {
        flex-direction: column;
        align-items: center;
        padding-block: 1rem;
    }

    .features-content {
        max-width: 80%;
        padding: 1.5rem;
        text-align: center;
    }

    .features-content h3 {
        font-size: 1rem;
    }

    .features-img-wrapper img {
        max-width: 85%;
    }
}

/* Extra small devices (phones, small tablets) */
@media (max-width: 480px) {
    .section-title {
        text-align: center;
        padding-block: 1.5rem;
    }

    .section-title h1 {
        padding-left: 0;
        font-size: 1.4rem;
    }

    .line {
        width: 30%;
    }

    .fetch {
        padding-right: 1rem;
    }

    .features-content {
        max-width: 90%;
        padding: 1rem;
    }

    .features-content h3 {
        font-size: 0.9rem;
    }

    .features-img-wrapper img {
        max-width: 90%;
    }
}